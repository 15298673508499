import React from 'react'
import Markdown from 'react-markdown'

class PersonIntro extends React.Component {
  render() {
    const { data } = this.props
    return (
      <div className='person-intro-wrap'>
        <img
          src={
            data.image_link
              ? data.image_link
              : `${process.env.STRAPI_URL}${data.image && data.image.url}`
          }
          alt='头像'
        />
        <div className='person-intro-r'>
          <p className='name'>{data.name}</p>
          <p className='title'>{data.position}</p>
          <div className='intros'>
            <Markdown source={data.description} escapeHtml={false} />
          </div>
        </div>
      </div>
    )
  }
}
export default PersonIntro
