import React from 'react'
import { Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PersonIntro from '../components/antd/PersonIntro'
import '../components/antd/less/antMotionStyle.less'

class TeamIntro extends React.PureComponent {
  compare = prop => {
    return (a, b) => {
      return a[prop] - b[prop]
    }
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [], teams = [] } = strapi
    const teamsData = teams.sort(this.compare('sequence'))

    return (
      <Layout
        curIndex={5}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='新闻动态' />
        <div className='team-intro-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>团队介绍</h2>
            <p className='intro'>
              前IBM中国研究院院长、IBM全球杰出工程师
              领衔，来自前IBM中国研究院、京东金融、华为等技术骨干团队，全球GPU容器云技术创始人AI深度学习加速器架构资深专家认知物联技术资深专家，区块链开源代码全球顶级贡献者，来自北大、西交、北航等众多著名高校的博士硕士研发队伍。
            </p>
            <OverPack playScale={0.3}>
              <QueueAnim type='bottom' key='block' leaveReverse component={Row}>
                <div className='team-members'>
                  <Row gutter={24}>
                    {teamsData.map(item => (
                      <Col span={item.span} key={item.id}>
                        <PersonIntro data={item} />
                      </Col>
                    ))}
                  </Row>
                </div>
              </QueueAnim>
            </OverPack>
          </div>
        </div>
      </Layout>
    )
  }
}
export default TeamIntro
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
      teams {
        id
        name
        position
        image {
          url
        }
        image_link
        description
        span
        sequence
      }
    }
  }
`
